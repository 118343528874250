/*eslint-disable */
window.MY_CONSENT_PREFERENCES = {purposes: {1: true}};
window.MY_CONSENT_CALLBACK = () => {
};
/* eslint-enable */
/*eslint-disable */
(function () {
  if ( typeof window.CustomEvent === "function" ) return false; //If not IE

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();
/* eslint-enable */
(
  function( window ) {
    let consenter = {};
    let js_srcs = [];
    let js_lib = {};
    let reg_functions = {};

    let additionalCallbacks = [];

    /**
     * Startpoint for this script - executed by CMP callback
     * @param updated_preferences
     * @param full_consent
     */
    consenter.start = function( websitePreferences, tagManagerPreferences, fullConsent ) {
      logger( 'consenter started' );
      // contentpass handling
      if (typeof window._sp_ !== 'undefined' && window._sp_.config.targetingParams.acps === "true" ) {
        websitePreferences = { 1: true };
        tagManagerPreferences = { 2: false, 3: false, 6: false };
      } 
      /*eslint-disable */
      window.MY_CONSENT_PREFERENCES.purposes = websitePreferences;
      /* eslint-enable */
      firePreferencesEvents( websitePreferences );
      writePreferencesToDOM( websitePreferences );
      loadAll();
      initDataLayer( tagManagerPreferences, fullConsent );
    };

    /**
     * Add callback to be executed after given script is loaded.
     * @param name
     * @param callback
     */
    consenter.addAfterLoadCallback = function( name, callback ) {
      if ( !scriptLoaded( name ) ) {
        additionalCallbacks.push( {name: name, callback: callback} );
        return;
      }

      callback();
    };

    /**
     * Register Scripts to load after consent is given
     * @param name of the script has to be a valid js Object key
     * @param src of the script
     * @param preference consent preference (between 1..5)
     * @param async ether async or defer
     * @param dep array of dependencies
     */
    consenter.loadJS = function( name, src, preference = 1, async = 'async', dep = [] ) {
      js_srcs.push( {name: name, src: src, preference: preference, async: async, deps: dep} );
      js_lib[name] = {name: name, loaded: false};
      loadAll();
    };

    /**
     * manually register scripts as loaded (for blocking loaded scripts)
     * @param name of the script has to be a valid js Object key
     */
    consenter.libIsLoaded = function( name ) {
      js_lib[name] = {name: name, loaded: true};

      for ( let afterLoadCb of additionalCallbacks ) {
        if ( name === afterLoadCb.name ) {
          afterLoadCb.callback();
          additionalCallbacks.splice( additionalCallbacks.indexOf( afterLoadCb ), 1 );
        }
      }

      const evt = new CustomEvent( getDepEvtName( name ) );
      window.dispatchEvent( evt );
    };

    /**
     * wait with execution of callback until all dependencies are resolved
     * @param deps
     * @param next callback
     */
    consenter.waitForDependency = function( deps, next ) {
      consenter.waitFor( [], deps, next, false );
    };

    /**
     * wait with execution of callback until one of the CMP Preferences is given
     * @param cmpPref
     * @param next callback
     */
    consenter.waitForCmpPref = function( cmpPref, next ) {
      consenter.waitFor( cmpPref, [], next, false );
    };

    /**
     * wait with execution of cb function until all dependencies are resolved
     * @param cmpPref array with CMP preferences
     * @param deps array with dependencies
     * @param next Callback function
     */
    consenter.waitFor = function( cmpPref, deps, next ) {
      const preferences = ensureArray( cmpPref );
      const dependencies = ensureArray( deps );
      const id = getUniquID();
      reg_functions[id] = {cmpPref: preferences, deps: dependencies, next: next};

      if ( checkIfCMPPrefsAreMet( preferences ) === true && checkIfDepsAreMet( dependencies ) === true && reg_functions[id] ) {
        runScript( id );
      } else {
        preferences.every( ( myPref ) => {
          if ( !checkConsent( myPref ) ) {
            logger( 'create listener for: ' + getPrevEvtName( myPref ) );
            addEventListenerOnce( window, getPrevEvtName( myPref ), () => {
              logger( 'execute listener for: ' + getPrevEvtName( myPref ) );
              runScript( id );
            } );
          }
          return true;
        } );
        dependencies.every( ( myDep ) => {
          if ( js_lib[myDep] && js_lib[myDep].loaded === false ) {
            logger( 'create listener for: ' + getDepEvtName( myDep ) );
            addEventListenerOnce( window, getDepEvtName( myDep ), () => {
              logger( 'execute listener for: ' + getDepEvtName( myDep ) );
              runScript( id );
            } );
          }
          return true;
        } );
      }
    };

    /**
     * Run an inline script which is registered by waitFor functions
     * @param id of the Script
     */
    function runScript( id ) {
      if ( reg_functions[id] ) {
        const script = reg_functions[id];
        if ( checkIfCMPPrefsAreMet( script.cmpPref ) === true && checkIfDepsAreMet( script.deps ) === true ) {
          logger( 'all dependencies met, now run script with pref: ' + script.cmpPref + ' and dep: ' + script.deps + ' and ID: ' + id );
          script.next();
          reg_functions[id] = false;
        }
      }
    }

    /**
     * Ensure the value is an Array
     * @param value
     * @return {*[]|*}
     */
    function ensureArray( value ) {
      if ( !Array.isArray( value ) ) {
        return [value];
      }
      return value;
    }

    /**
     * Helper function to create a Event Listener which deletes itself on first execution
     * @param target Element
     * @param type Event Name
     * @param listener Callback function
     * @param addOptions
     * @param removeOptions
     */
    function addEventListenerOnce( target, type, listener, addOptions, removeOptions ) {
      target.addEventListener( type, function fn( event ) {
        target.removeEventListener( type, fn, removeOptions );
        listener.apply( this, arguments );
      }, addOptions );
    }

    /**
     * returns a unique Number based on Microtime and a random number
     */
    function getUniquID() {
      return (
        Date.now() + Math.floor( Math.random() * 100 )
      ).toString( 16 );
    }

    /**
     * Checks if the needed consent is given
     * @param cmpPref
     * @return {boolean}
     */
    function checkIfCMPPrefsAreMet( cmpPref ) {
      if ( cmpPref.length === 0 ) {
        return true;
      }
      return cmpPref.some( ( pref ) => checkConsent( pref ) );
    }

    /**
     * Checks the given Consent
     * @param  pref Level
     * @return {boolean}
     */
    function checkConsent( pref ) {
      return !!(
        typeof window.MY_CONSENT_PREFERENCES.purposes !== 'undefined' && window.MY_CONSENT_PREFERENCES.purposes[pref]
      );
    }

    /**
     * Checks if all dependencies are met
     * @param {Array} deps List of dependencies.
     * @return {boolean}
     */
    function checkIfDepsAreMet( deps ) {
      if ( deps.every( ( handle ) => (
        handle === ''
      ) ) ) {
        return true;
      }
      let go_on = false;
      deps.every( ( dep ) => {
        go_on = !!(
          js_lib[dep] && js_lib[dep].loaded
        );
        return true;
      } );

      return go_on;
    }

    /**
     * Generate the event name for dependencies
     * @param name
     * @return {string}
     */
    function getDepEvtName( name ) {
      return 'scriptIsLoaded_' + name;
    }

    /**
     * Generate the event name for CMP preferences
     * @param pref
     * @return {string}
     */
    function getPrevEvtName( pref ) {
      return 'cmpPreferenceIsGiven_' + pref;
    }

    function scriptLoaded( name ) {
      for ( let libname in js_lib ) {
        if ( js_lib[libname].name === name && js_lib[libname].loaded ) {
          return true;
        }
      }

      return false;
    }

    /**
     * load all registered Scripts if the consent is given
     */
    function loadAll() {
      if (
        js_srcs.length > 0 &&
        typeof window.MY_CONSENT_PREFERENCES.purposes !== 'undefined'
      ) {
        for ( let i = 0; i < js_srcs.length; i++ ) {
          let js = js_srcs[i];
          if (
            js.preference !== undefined &&
            window.MY_CONSENT_PREFERENCES.purposes[js.preference] === true
          ) {
            let go_on = false;
            if ( js.deps.length > 0 ) {
              js.deps.every( ( dep ) => {
                if ( js_lib[dep] && js_lib[dep].loaded ) {
                  go_on = true;
                } else {
                  go_on = false;
                  addEventListenerOnce( window, getDepEvtName( dep ), () => loadAll() );
                }
                return true;
              } );
            } else {
              go_on = true;
            }
            if ( go_on ) {
              jsLoader( js.src, js.name, js.async );
              js_srcs.splice( i, 1 );
              i--;
            }
          }
        }
      }
    }

    /**
     * load a JS file asynchronously
     *
     * @see https://github.com/filamentgroup/loadJS/
     * @param src
     * @param name
     * @param async
     * @return {HTMLScriptElement}
     */
    function jsLoader( src, name, async = 'async' ) {
      // let ref = window.document.getElementsByTagName( 'script' )[0];
      let script = window.document.createElement( 'script' );
      script.src = src;
      if ( async === 'async' ) {
        script.async = true;
      } else {
        script.defer = true;
      }
      script.id = name;
      script.onload = script.onreadystatechange = function() {
        script.onreadystatechange = script.onload = null;
        consenter.libIsLoaded( name );
      };
      document.head.appendChild( script );
      // ref.parentNode.insertBefore( script, ref );
      return script;
    }

    /**
     * If the consent is given we fire Events for every consent preference.
     * @param preference the given Preferences
     */
    function firePreferencesEvents( preference ) {
      if ( preference ) {
        for ( let purposeId in preference ) {
          if ( preference[purposeId] ) {
            const evt = new Event( getPrevEvtName( purposeId ) );
            window.dispatchEvent( evt );
          }
        }
      }
    }

    /**
     * If the consent is given we write consent information to the DOM for CSS handling.
     * @param preference the given Preferences
     */
    function writePreferencesToDOM( preference ) {
      for ( let purposeId in preference ) {
        if ( preference[purposeId] ) {
          let html = document.getElementsByTagName( 'html' )[0];
          html.classList.add( 'cmp-consent-level-' + purposeId );
        }
      }
    }

    /**
     * Init the Google Tagmanager Datalayer with the CMP preferences
     */
    function initDataLayer( preferences, fullConsent ) {
      /*eslint-disable */
      window.dataLayer.push( {
        'cmp_data': fullConsent,
        'event': 'cmp_consent_test'
      } );

      if ( preferences[window.tcfApiConsentAdapter.UTOPIA_PURPOSES.WRITE_COOKIES] )
        window.dataLayer.push( {
          'cmp_cookies': true,
          'event': 'cmp_cookies_on'
        } );

      if ( !preferences[window.tcfApiConsentAdapter.UTOPIA_PURPOSES.ANALYTICS] )
        window.dataLayer.push( {
          'cmp_analytics': false,
          'event': 'cmp_analytics_off'
        } );
      else
        window.dataLayer.push( {
          'cmp_analytics': true,
          'event': 'cmp_analytics_on'
        } );
      
      if ( preferences[window.tcfApiConsentAdapter.UTOPIA_PURPOSES.FULL_CONSENT] )
        window.dataLayer.push( {
          'cmp_full_consent': true,
          'event': 'cmp_full_consent'
        } );
        
      /*eslint-enable */
    }

    function logger( message ) {
      console.log( message ); // eslint-disable-line no-console
    }

    // expose the 'consenter' object globally. Use it to expose anything in here that's useful to other parts of your application.
    window.consenter = consenter;
    window.MY_CONSENT_CALLBACK = window.consenter.start;
  }
)( window );
